exports.components = {
  "component---common-src-pages-card-stacker-tsx": () => import("./../../../../common/src/pages/cardStacker.tsx" /* webpackChunkName: "component---common-src-pages-card-stacker-tsx" */),
  "component---common-src-pages-hair-quiz-basket-tsx": () => import("./../../../../common/src/pages/hairQuizBasket.tsx" /* webpackChunkName: "component---common-src-pages-hair-quiz-basket-tsx" */),
  "component---common-src-pages-preview-tsx": () => import("./../../../../common/src/pages/preview.tsx" /* webpackChunkName: "component---common-src-pages-preview-tsx" */),
  "component---common-src-templates-about-us-index-tsx": () => import("./../../../../common/src/templates/AboutUs/index.tsx" /* webpackChunkName: "component---common-src-templates-about-us-index-tsx" */),
  "component---common-src-templates-advanced-text-page-index-tsx": () => import("./../../../../common/src/templates/AdvancedTextPage/index.tsx" /* webpackChunkName: "component---common-src-templates-advanced-text-page-index-tsx" */),
  "component---common-src-templates-bag-page-index-tsx": () => import("./../../../../common/src/templates/BagPage/index.tsx" /* webpackChunkName: "component---common-src-templates-bag-page-index-tsx" */),
  "component---common-src-templates-check-order-status-index-tsx": () => import("./../../../../common/src/templates/CheckOrderStatus/index.tsx" /* webpackChunkName: "component---common-src-templates-check-order-status-index-tsx" */),
  "component---common-src-templates-checkout-confirmation-index-tsx": () => import("./../../../../common/src/templates/CheckoutConfirmation/index.tsx" /* webpackChunkName: "component---common-src-templates-checkout-confirmation-index-tsx" */),
  "component---common-src-templates-contact-us-index-tsx": () => import("./../../../../common/src/templates/ContactUs/index.tsx" /* webpackChunkName: "component---common-src-templates-contact-us-index-tsx" */),
  "component---common-src-templates-forgotten-password-index-tsx": () => import("./../../../../common/src/templates/ForgottenPassword/index.tsx" /* webpackChunkName: "component---common-src-templates-forgotten-password-index-tsx" */),
  "component---common-src-templates-home-index-tsx": () => import("./../../../../common/src/templates/Home/index.tsx" /* webpackChunkName: "component---common-src-templates-home-index-tsx" */),
  "component---common-src-templates-login-index-tsx": () => import("./../../../../common/src/templates/Login/index.tsx" /* webpackChunkName: "component---common-src-templates-login-index-tsx" */),
  "component---common-src-templates-not-found-index-tsx": () => import("./../../../../common/src/templates/NotFound/index.tsx" /* webpackChunkName: "component---common-src-templates-not-found-index-tsx" */),
  "component---common-src-templates-product-category-page-index-tsx": () => import("./../../../../common/src/templates/ProductCategoryPage/index.tsx" /* webpackChunkName: "component---common-src-templates-product-category-page-index-tsx" */),
  "component---common-src-templates-product-collection-page-index-tsx": () => import("./../../../../common/src/templates/ProductCollectionPage/index.tsx" /* webpackChunkName: "component---common-src-templates-product-collection-page-index-tsx" */),
  "component---common-src-templates-product-index-tsx": () => import("./../../../../common/src/templates/Product/index.tsx" /* webpackChunkName: "component---common-src-templates-product-index-tsx" */),
  "component---common-src-templates-profile-page-index-tsx": () => import("./../../../../common/src/templates/ProfilePage/index.tsx" /* webpackChunkName: "component---common-src-templates-profile-page-index-tsx" */),
  "component---common-src-templates-search-index-tsx": () => import("./../../../../common/src/templates/Search/index.tsx" /* webpackChunkName: "component---common-src-templates-search-index-tsx" */),
  "component---common-src-templates-sign-up-index-tsx": () => import("./../../../../common/src/templates/SignUp/index.tsx" /* webpackChunkName: "component---common-src-templates-sign-up-index-tsx" */),
  "component---common-src-templates-sitemap-index-tsx": () => import("./../../../../common/src/templates/Sitemap/index.tsx" /* webpackChunkName: "component---common-src-templates-sitemap-index-tsx" */),
  "component---common-src-templates-store-locator-index-tsx": () => import("./../../../../common/src/templates/StoreLocator/index.tsx" /* webpackChunkName: "component---common-src-templates-store-locator-index-tsx" */)
}

