import React, { FunctionComponent, useEffect } from "react";
import { event2 } from "../../../analytics/event2";
import { event3 } from "../../../analytics/event3";
import { event4 } from "../../../analytics/event4";
import { event48 } from "../../../analytics/event48";
import { event49 } from "../../../analytics/event49";
import { event57 } from "../../../analytics/event57";
import { event63 } from "../../../analytics/event63";
import { event68 } from "../../../analytics/event68";
import { event26 } from "../../../analytics/event26";

const clickHandlers = [event2, event3, event4, event48, event49, event57, event26, event63, event68];

const ClickTracker: FunctionComponent = ({ children }) => {
  useEffect(() => {
    clickHandlers.forEach(clickHandler => document.addEventListener("click", clickHandler));
  }, []);

  return <>{children}</>;
};

export default ClickTracker;
