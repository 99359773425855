import { EventType, pushEvent } from "./index";

/**
 * Registration Start
 */

export const event26 = (e: MouseEvent) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;

  const formElement = (e.target as Element).closest("[data-analytics-event26]");
  if (!formElement) return;

  const pageURL = typeof window !== "undefined" ? window.location.href : "";
  if (!pageURL) return;

  if (win.registrationStarted) {
    return;
  }

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.RegistrationStart,
      eventLabel: pageURL,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: EventType.lead
  });
  win.registrationStarted = true;
};
