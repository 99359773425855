import React, { FunctionComponent, useEffect } from "react";
import { event67 } from "../../../analytics/event67";

const ScrollTracker: FunctionComponent = () => {
  let sequenceControl = 0;
  let oldPath = "";

  const handleScroll = event => {
    // 100% before reach the footer
    const footerHeight = document.getElementById("footer")?.scrollHeight;
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight - footerHeight;
    const scrolled = (winScroll / height) * 100;

    if (oldPath !== event.target?.location.pathname) {
      sequenceControl = 0;
      oldPath = event.target?.location.pathname;
    }
    if (scrolled >= 25 && scrolled < 50 && sequenceControl === 0) {
      event67("25%");
      sequenceControl = 1;
    }
    if (scrolled >= 50 && scrolled < 75 && sequenceControl === 1) {
      event67("50%");
      sequenceControl = 2;
    }
    if (scrolled >= 75 && scrolled < 100 && sequenceControl === 2) {
      event67("75%");
      sequenceControl = 3;
    }
    if (scrolled >= 100 && sequenceControl === 3) {
      event67("100%");
      sequenceControl = -1;
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", e => handleScroll(e));
    return () => {
      window.removeEventListener("scroll", e => handleScroll(e));
    };
  }, []);

  return <></>;
};

export default ScrollTracker;
