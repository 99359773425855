import { EventType, pushEvent } from "./index";

/**
 * Anchor Link Click
 */
export const event3 = (e: MouseEvent) => {
  const link = (e.target as Element).closest("a");
  // not an 'a', missing 'href' or is NOT a hash/anchor link to elsewhere on the current page

  if (!link || (link && !link.href) || link.href.indexOf("#") === -1) return;

  let text = "";
  if (link) {
    text = (link.textContent === "" ? link.getAttribute("aria-label") : link.textContent?.trim()) + " - " + link.href;
  }

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.anchorLinkClicked,
      eventLabel: `${text}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.custom
    }
  });
};
